var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "parkCardManageForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "saveAfterSlot",
                fn: function () {
                  return [
                    _c("v-button", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.delFlag,
                          expression: "delFlag",
                        },
                      ],
                      attrs: { type: "danger", text: "删除车卡" },
                      on: {
                        click: function ($event) {
                          return _vm.delCard()
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "用户信息" } },
                [
                  _vm.isAdd && _vm.canEditFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手机号",
                            prop: "mobileNum",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: "请输入手机号",
                                      width: 500,
                                    },
                                    on: { onChange: _vm.userChange },
                                    model: {
                                      value: _vm.form.mobileNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "mobileNum", $$v)
                                      },
                                      expression: "form.mobileNum",
                                    },
                                  },
                                  "v-select2",
                                  _vm.userParams,
                                  false
                                )
                              ),
                              _vm.isAdd
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { type: "primary" },
                                      on: { click: _vm.addUser },
                                    },
                                    [_vm._v("添加新用户")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "手机号",
                          text: _vm.form.mobileNum,
                        },
                      }),
                  _vm.isAdd && _vm.canEditFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "姓名",
                            prop: "userName",
                            rules: [
                              {
                                required: true,
                                message: "请输入姓名",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入姓名",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userName", $$v)
                              },
                              expression: "form.userName",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "姓名",
                          text: _vm.form.userName,
                        },
                      }),
                  _vm.isAdd && _vm.canEditFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            placeholder: "请选择证件类型",
                            label: "证件类型",
                            prop: "userCardType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.cardTypeOpts,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.userCardType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userCardType", $$v)
                              },
                              expression: "form.userCardType",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "证件类型",
                          text: _vm.cardTypeMap[_vm.form.userCardType],
                        },
                      }),
                  _vm.isAdd && _vm.canEditFlag
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "证件号", prop: "userCardNum" } },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入证件号",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.userCardNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userCardNum", $$v)
                              },
                              expression: "form.userCardNum",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "证件号",
                          text: _vm.form.userCardNum,
                        },
                      }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "停车信息" } },
                [
                  _c(
                    "div",
                    { staticClass: "add-card" },
                    [
                      _vm._l(_vm.form.carLength, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: { active: _vm.active == item.cardNum },
                            on: {
                              click: function ($event) {
                                return _vm.changeCard(item.cardNum)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            停车" +
                                _vm._s(index + 1) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.addCard()
                            },
                          },
                        },
                        [_vm._v("+")]
                      ),
                    ],
                    2
                  ),
                  !_vm.isAdd
                    ? _c("col2-item", {
                        staticClass: "car-number",
                        attrs: {
                          span: 24,
                          label: "编号",
                          text: _vm.form.cardNum,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌号" } },
                    [
                      _vm._l(_vm.form.plateNumberList, function (item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            staticClass: "content-wrapper",
                            attrs: {
                              prop: "plateNumberList." + index + ".plateNumber",
                            },
                          },
                          [
                            item.plateNumber
                              ? _c("v-checkbox", {
                                  attrs: {
                                    label: item.plateNumber,
                                    value: item.plateNumber,
                                  },
                                  model: {
                                    value: item.isChecked,
                                    callback: function ($$v) {
                                      _vm.$set(item, "isChecked", $$v)
                                    },
                                    expression: "item.isChecked",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _c("v-button", {
                        attrs: { text: "新增车辆", width: _vm.width },
                        on: {
                          click: function ($event) {
                            return _vm.plusMinusAction(true, 0)
                          },
                        },
                      }),
                    ],
                    2
                  ),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属项目",
                            prop: "communityId",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  placeholder: "查询项目",
                                  width: _vm.width,
                                  disabled: true,
                                },
                                on: { onChange: _vm.projectChange },
                                model: {
                                  value: _vm.form.communityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "communityId", $$v)
                                  },
                                  expression: "form.communityId",
                                },
                              },
                              "v-select2",
                              _vm.projectParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "所属项目" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.communityName))]),
                      ]),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "车位类型",
                            prop: "rentType",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.rentTypeOps,
                              width: _vm.width,
                            },
                            on: { change: _vm.truckTypeChange },
                            model: {
                              value: _vm.form.rentType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rentType", $$v)
                              },
                              expression: "form.rentType",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "车位类型" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.rentTypeMap[_vm.form.rentType])),
                        ]),
                      ]),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            placeholder: "请选择租赁套餐",
                            label: "套餐名称",
                            prop: "suitId",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                ref: "suit",
                                attrs: {
                                  placeholder: "选择套餐",
                                  disabled: !_vm.form.communityId,
                                  width: _vm.width,
                                  subjoin: _vm.suitSubjoin,
                                  isWatch: _vm.isWatch,
                                  isRecord: _vm.isRecord,
                                },
                                on: { onChange: _vm.suitChange },
                                model: {
                                  value: _vm.form.suitId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "suitId", $$v)
                                  },
                                  expression: "form.suitId",
                                },
                              },
                              "v-select2",
                              _vm.suitParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "选择套餐" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.suitName))]),
                      ]),
                  _vm.isAdd && _vm.form.rentType != 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "roomNumId",
                            label: "车位位置",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                ref: "room",
                                attrs: {
                                  placeholder: "选择车位",
                                  disabled: !_vm.form.communityId,
                                  subjoin: _vm.parkSubjoin,
                                  width: _vm.width,
                                  isWatch: _vm.isWatch,
                                  isRecord: _vm.isRecord,
                                },
                                on: { onChange: _vm.roomChange },
                                model: {
                                  value: _vm.form.roomNumId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "roomNumId", $$v)
                                  },
                                  expression: "form.roomNumId",
                                },
                              },
                              "v-select2",
                              _vm.parkParams1,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isAdd && _vm.form.rentType != 2
                    ? _c("el-form-item", { attrs: { label: "车位位置" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.roomNumName))]),
                      ])
                    : _vm._e(),
                  _vm.isAdd && _vm.form.rentType !== 3
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "租赁时间",
                            prop: "purchaseCount",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("div", { staticClass: "plant-category-wrapper" }, [
                            _c(
                              "div",
                              { staticClass: "content-wrapper" },
                              [
                                _c("v-input-number", {
                                  attrs: { width: 80, min: 1, max: 999999999 },
                                  on: { blur: _vm.countChange },
                                  model: {
                                    value: _vm.form.purchaseCount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "purchaseCount", $$v)
                                    },
                                    expression: "form.purchaseCount",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "right-tip",
                                    staticStyle: { margin: "0 20px 0 10px" },
                                  },
                                  [_vm._v(_vm._s(_vm.dayType))]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm.form.rentType != 3
                    ? [
                        _vm.form.rentType != 3
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _vm.form.rentType != 3
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        on: { change: _vm.radioTypeChange },
                                        model: {
                                          value: _vm.form.radioTypeLabel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "radioTypeLabel",
                                              $$v
                                            )
                                          },
                                          expression: "form.radioTypeLabel",
                                        },
                                      },
                                      [
                                        _c("el-radio-button", {
                                          attrs: { label: "续期" },
                                        }),
                                        _vm.form.status == 1
                                          ? [
                                              _c("el-radio-button", {
                                                attrs: { label: "终止" },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.type == 1 && _vm.form.rentType != 3
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "续费周期",
                                    prop: "purchaseCount",
                                  },
                                },
                                [
                                  _c("v-input-number", {
                                    attrs: {
                                      controls: "",
                                      controlsPosition: "right",
                                      width: 80,
                                    },
                                    on: {
                                      change: _vm.countChange,
                                      blur: _vm.countBlur,
                                    },
                                    model: {
                                      value: _vm.form.purchaseCount,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "purchaseCount", $$v)
                                      },
                                      expression: "form.purchaseCount",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "right-tip",
                                      staticStyle: { margin: "0 20px 0 10px" },
                                    },
                                    [_vm._v(_vm._s(_vm.dayType))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.form.type == 2
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "提前终止周期",
                                    prop: "purchaseCount",
                                  },
                                },
                                [
                                  _c("v-input-number", {
                                    attrs: {
                                      width: 80,
                                      max: _vm.max,
                                      controls: "",
                                      controlsPosition: "right",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.countChange(2)
                                      },
                                      blur: _vm.countBlur,
                                    },
                                    model: {
                                      value: _vm.form.purchaseCount,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "purchaseCount", $$v)
                                      },
                                      expression: "form.purchaseCount",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "right-tip",
                                      staticStyle: { margin: "0 20px 0 10px" },
                                    },
                                    [_vm._v(_vm._s(_vm.dayType))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.isAdd || (!_vm.isAdd && _vm.form.rentType == 3)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "有效周期",
                            rules: [
                              {
                                required: true,
                                message: "请选择有效周期",
                                trigger: "change",
                              },
                            ],
                            prop: "startTime",
                          },
                        },
                        [
                          _vm.form.rentType == 3
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "order-checkbox",
                                    attrs: { label: "长期有效" },
                                    on: { change: _vm.onLongEffectiveChange },
                                    model: {
                                      value: _vm.longEffective,
                                      callback: function ($$v) {
                                        _vm.longEffective = $$v
                                      },
                                      expression: "longEffective",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.rentType == 3 && _vm.longEffective
                            ? _c("v-datepicker2", {
                                attrs: {
                                  startTime: _vm.form.startTime,
                                  endTime: _vm.form.endTime,
                                  type: "rangedatetimer",
                                  "end-disabled": true,
                                },
                                on: {
                                  "update:startTime": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "startTime",
                                      $event
                                    )
                                  },
                                  "update:start-time": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "startTime",
                                      $event
                                    )
                                  },
                                  "update:endTime": function ($event) {
                                    return _vm.$set(_vm.form, "endTime", $event)
                                  },
                                  "update:end-time": function ($event) {
                                    return _vm.$set(_vm.form, "endTime", $event)
                                  },
                                },
                              })
                            : _c("v-datepicker", {
                                attrs: {
                                  startTime: _vm.form.startTime,
                                  endTime: _vm.form.endTime,
                                  type: "rangedatetimer",
                                },
                                on: {
                                  "update:startTime": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "startTime",
                                      $event
                                    )
                                  },
                                  "update:start-time": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "startTime",
                                      $event
                                    )
                                  },
                                  "update:endTime": function ($event) {
                                    return _vm.$set(_vm.form, "endTime", $event)
                                  },
                                  "update:end-time": function ($event) {
                                    return _vm.$set(_vm.form, "endTime", $event)
                                  },
                                  schange: _vm.dateChange,
                                },
                              }),
                        ],
                        1
                      )
                    : _vm.form.rentType !== 3
                    ? _c("el-form-item", { attrs: { label: "有效周期" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.form.startTime) +
                              " —— " +
                              _vm._s(_vm.form.endTime)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.isAdd
                    ? _c("el-form-item", { attrs: { label: "剩余有效天数" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.leftDay) + "天")]),
                      ])
                    : _vm._e(),
                  !_vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "车卡状态",
                            prop: "status",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.carCardStatusOps,
                              width: _vm.width,
                              disabled: !_vm.isAdd,
                            },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("v-textarea", {
                        attrs: { placeholder: "备注" },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.payFlag && _vm.form.rentType !== 3
                ? _c(
                    "col2-block",
                    { attrs: { title: "支付信息" } },
                    [
                      _c(
                        "div",
                        { staticClass: "money-bottom" },
                        [
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "订单金额",
                              text:
                                (_vm.form.type == 2 ? "-" : "") +
                                "￥" +
                                _vm.form.orderMoneyTotal,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "优惠金额",
                            prop: "orderMoneyDiscount",
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              width: _vm.width,
                              min: 0,
                              max: parseInt(_vm.form.orderMoneyTotal),
                            },
                            on: { blur: _vm.orderMoneyDiscountBlur },
                            model: {
                              value: _vm.form.orderMoneyDiscount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderMoneyDiscount", $$v)
                              },
                              expression: "form.orderMoneyDiscount",
                            },
                          }),
                          _vm._v("\n          元\n        "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "money-bottom" },
                        [
                          _c("col2-item", {
                            attrs: {
                              span: 24,
                              label: "实付金额",
                              text:
                                (_vm.form.type == 2 ? "-" : "") +
                                "￥" +
                                _vm.orderMoney,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "支付方式",
                            prop: "payment",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.paymentOpts,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.payment,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "payment", $$v)
                              },
                              expression: "form.payment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("remark-log-dialog", {
        attrs: { visible: _vm.remarkLogVisible },
        on: {
          "update:visible": function ($event) {
            _vm.remarkLogVisible = $event
          },
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "添加新用户",
            visible: _vm.userDialog,
            width: "440px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userDialog = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "userForm",
              attrs: {
                model: _vm.userForm,
                rule: _vm.rule,
                "label-width": "140px",
                "label-suffix": "：",
                size: "medium",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "姓名",
                    prop: "ownerName",
                    rules: [
                      {
                        required: true,
                        message: "请填写姓名",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    model: {
                      value: _vm.userForm.ownerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.userForm, "ownerName", $$v)
                      },
                      expression: "userForm.ownerName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "手机号",
                    prop: "ownerPhone",
                    rules: [
                      {
                        required: true,
                        message: "请填写手机号",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    model: {
                      value: _vm.userForm.ownerPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.userForm, "ownerPhone", $$v)
                      },
                      expression: "userForm.ownerPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "证件类型",
                    prop: "cardType",
                    rules: [
                      {
                        required: true,
                        message: "请选择证件类型",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: { options: _vm.cardTypeOpts, width: _vm.width },
                    model: {
                      value: _vm.userForm.cardType,
                      callback: function ($$v) {
                        _vm.$set(_vm.userForm, "cardType", $$v)
                      },
                      expression: "userForm.cardType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "证件号",
                    prop: "cardNum",
                    rules: [
                      {
                        required: true,
                        message: "请输入证件号",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: { placeholder: "请输入证件号", width: _vm.width },
                    model: {
                      value: _vm.userForm.cardNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.userForm, "cardNum", $$v)
                      },
                      expression: "userForm.cardNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属项目",
                    prop: "communityId",
                    rules: {
                      required: true,
                      message: "请选择所属项目",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        ref: "userCommunity",
                        attrs: { placeholder: "查询项目", width: _vm.width },
                        model: {
                          value: _vm.userForm.communityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.userForm, "communityId", $$v)
                          },
                          expression: "userForm.communityId",
                        },
                      },
                      "v-select2",
                      _vm.projectParams,
                      false
                    )
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所在房号",
                    prop: "roomId",
                    rules: {
                      required: true,
                      message: "请选择所在房号",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: {
                          subjoin: _vm.userRoomSubjoin,
                          placeholder: "选择房号",
                          width: _vm.width,
                          disabled:
                            !_vm.userForm.communityId ||
                            _vm.userForm.communityId.length === 0,
                        },
                        model: {
                          value: _vm.userForm.roomId,
                          callback: function ($$v) {
                            _vm.$set(_vm.userForm, "roomId", $$v)
                          },
                          expression: "userForm.roomId",
                        },
                      },
                      "v-select2",
                      _vm.userRoomParams,
                      false
                    )
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份",
                    prop: "userType",
                    rules: [
                      {
                        required: true,
                        message: "请选择身份",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: { options: _vm.userTypeOps },
                    model: {
                      value: _vm.userForm.userType,
                      callback: function ($$v) {
                        _vm.$set(_vm.userForm, "userType", $$v)
                      },
                      expression: "userForm.userType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitOpera } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "carDialog",
          attrs: {
            title: "添加车辆",
            visible: _vm.addCarDialog,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addCarDialog = $event
            },
            close: _vm.closeCarDialog,
          },
        },
        [
          _c("AddCarDialog", {
            attrs: {
              userId: _vm.form.userId,
              houseId: _vm.form.userHouseId,
              communityId: _vm.form.communityId,
              edit: _vm.isEdit,
            },
            on: { submitSuccess: _vm.handleAddCarSuccess },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }