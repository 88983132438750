<template>
  <div class="parkCardManageForm-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      submitText="保存"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <col2-detail>
        <col2-block title="用户信息">
          <el-form-item
            v-if="isAdd && canEditFlag"
            label="手机号"
            prop="mobileNum"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change',
              },
            ]"
          >
            <div style="display: flex">
              <v-select2
                v-model="form.mobileNum"
                placeholder="请输入手机号"
                v-bind="userParams"
                @onChange="userChange"
                :width="500"
              ></v-select2>
              <el-button
                type="primary"
                @click="addUser"
                v-if="isAdd"
                style="margin-left: 10px"
                >添加新用户</el-button
              >
            </div>
          </el-form-item>
          <col2-item v-else :span="24" label="手机号" :text="form.mobileNum" />
          <el-form-item
            v-if="isAdd && canEditFlag"
            label="姓名"
            prop="userName"
            :rules="[
              { required: true, message: '请输入姓名', trigger: 'blur' },
            ]"
          >
            <v-input
              v-model="form.userName"
              placeholder="请输入姓名"
              :width="width"
            ></v-input>
          </el-form-item>
          <col2-item v-else :span="24" label="姓名" :text="form.userName" />
          <el-form-item
            v-if="isAdd && canEditFlag"
            placeholder="请选择证件类型"
            label="证件类型"
            prop="userCardType"
          >
            <v-select
              v-model="form.userCardType"
              :options="cardTypeOpts"
              :width="width"
            ></v-select>
          </el-form-item>
          <col2-item
            v-else
            :span="24"
            label="证件类型"
            :text="cardTypeMap[form.userCardType]"
          />
          <el-form-item
            v-if="isAdd && canEditFlag"
            label="证件号"
            prop="userCardNum"
          >
            <v-input
              v-model="form.userCardNum"
              placeholder="请输入证件号"
              :width="width"
            ></v-input>
          </el-form-item>
          <col2-item
            v-else
            :span="24"
            label="证件号"
            :text="form.userCardNum"
          />
        </col2-block>
        <!-- <col2-block title="车辆信息">
          <el-form-item
            label="名下可选车辆"
            prop="userName"
          >
            <checkbox-plus type="default" :options="orientTypeOps" :value.sync="checkValue" />
          </el-form-item>
          <el-form-item>
            <v-button>新增车辆</v-button>
          </el-form-item>
        </col2-block> -->
        <col2-block title="停车信息">
          <div class="add-card">
            <div
              :class="{ active: active == item.cardNum }"
              v-for="(item, index) in form.carLength"
              :key="index"
              @click="changeCard(item.cardNum)"
            >
              停车{{ index + 1 }}
            </div>
            <div @click="addCard()">+</div>
          </div>
          <col2-item
            v-if="!isAdd"
            :span="24"
            label="编号"
            :text="form.cardNum"
            class="car-number"
          />
          <el-form-item label="车牌号">
            <el-form-item
              class="content-wrapper"
              v-for="(item, index) in form.plateNumberList"
              :key="index"
              :prop="'plateNumberList.' + index + '.plateNumber'"
            >
              <v-checkbox 
                v-if="item.plateNumber" 
                :label="item.plateNumber" 
                :value="item.plateNumber" 
                v-model="item.isChecked"
              />
              <!-- <div v-else>{{item.plateNumber}}</div> -->
              <!-- <v-input
                class="first-margin"
                placeholder="输入车牌号"
                v-model="item.plateNumber"
                :maxlength="8"
                @change="inputChange(index)"
                :width="width"
              ></v-input> -->
              <!-- <div v-text="item.plateNumber"></div> -->
              <!-- <v-button
                v-if="isAdd && form.plateNumberList.length !== 1"
                type="danger"
                text="删除"
                @click="plusMinusAction(false, index)"
              ></v-button> -->
              <!-- <v-button
                type="danger"
                text="删除"
                @click="plusMinusAction(false, index)"
              ></v-button> -->
            </el-form-item>
            <v-button
              text="新增车辆"
              :width="width"
              @click="plusMinusAction(true, 0)"
            ></v-button>
          </el-form-item>
          <el-form-item
            v-if="isAdd"
            label="所属项目"
            prop="communityId"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change',
              },
            ]"
          >
            <v-select2
              placeholder="查询项目"
              v-model="form.communityId"
              v-bind="projectParams"
              @onChange="projectChange"
              :width="width"
              :disabled="true"
            ></v-select2>
          </el-form-item>
          <el-form-item v-else label="所属项目">
            <span>{{ form.communityName }}</span>
          </el-form-item>
          <el-form-item
            v-if="isAdd"
            label="车位类型"
            prop="rentType"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change',
              },
            ]"
          >
            <v-select
              v-model="form.rentType"
              :options="rentTypeOps"
              @change="truckTypeChange"
              :width="width"
            ></v-select>
          </el-form-item>
          <el-form-item v-else label="车位类型">
            <span>{{ rentTypeMap[form.rentType] }}</span>
          </el-form-item>
          <el-form-item
            v-if="isAdd"
            placeholder="请选择租赁套餐"
            label="套餐名称"
            prop="suitId"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change',
              },
            ]"
          >
            <v-select2
              placeholder="选择套餐"
              v-model="form.suitId"
              v-bind="suitParams"
              :disabled="!form.communityId"
              :width="width"
              :subjoin="suitSubjoin"
              @onChange="suitChange"
              :isWatch="isWatch"
              :isRecord="isRecord"
              ref="suit"
            ></v-select2>
          </el-form-item>
          <el-form-item v-else label="选择套餐">
            <span>{{ form.suitName }}</span>
          </el-form-item>
          <!-- <el-form-item
            v-if="isAdd && form.rentType == 2"
            prop="parkingId"
            label="车位位置"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change',
              },
            ]"
          >
            <v-select2
              v-model="form.parkingId"
              placeholder="选择停车场"
              v-bind="parkParams"
              :disabled="!form.communityId"
              :subjoin="parkSubjoin"
              :width="width"
              ref="park"
              :isWatch="isWatch"
              :isRecord="isRecord"
            ></v-select2>
          </el-form-item> -->
          <el-form-item
            v-if="isAdd && form.rentType != 2"
            prop="roomNumId"
            label="车位位置"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change',
              },
            ]"
          >
            <v-select2
              v-model="form.roomNumId"
              placeholder="选择车位"
              v-bind="parkParams1"
              :disabled="!form.communityId"
              :subjoin="parkSubjoin"
              :width="width"
              ref="room"
              :isWatch="isWatch"
              :isRecord="isRecord"
              @onChange="roomChange"
            ></v-select2>
          </el-form-item>
          <!-- <el-form-item v-if="!isAdd && form.rentType == 2" label="车位位置">
            <span>{{ form.parkingName }}</span>
          </el-form-item> -->
          <el-form-item v-if="!isAdd && form.rentType != 2" label="车位位置">
            <span>{{ form.roomNumName }}</span>
          </el-form-item>
          <el-form-item
            label="租赁时间"
            prop="purchaseCount"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'blur',
              },
            ]"
            v-if="isAdd && form.rentType !== 3"
          >
            <div class="plant-category-wrapper">
              <div class="content-wrapper">
                <v-input-number
                  v-model="form.purchaseCount"
                  :width="80"
                  :min="1"
                  :max="999999999"
                  @blur="countChange"
                />
                <span class="right-tip" style="margin: 0 20px 0 10px">{{
                  dayType
                }}</span>
              </div>
            </div>
          </el-form-item>
          <!-- 解决template中值为空存在空行问题 -->
          <template v-else-if="form.rentType != 3">
            <el-form-item label="" v-if="form.rentType != 3">
              <el-radio-group
                v-model="form.radioTypeLabel"
                @change="radioTypeChange"
                v-if="form.rentType != 3"
              >
                <el-radio-button label="续期"></el-radio-button>
                <template v-if="form.status == 1"
                  ><el-radio-button label="终止"></el-radio-button
                ></template>
              </el-radio-group>
            </el-form-item>
            <template v-if="form.type == 1 && form.rentType != 3">
              <el-form-item label="续费周期" prop="purchaseCount">
                <v-input-number
                  v-model="form.purchaseCount"
                  controls
                  controlsPosition="right"
                  :width="80"
                  @change="countChange"
                  @blur="countBlur"
                />
                <span class="right-tip" style="margin: 0 20px 0 10px">{{
                  dayType
                }}</span>
              </el-form-item>
            </template>
            <template v-if="form.type == 2">
              <el-form-item label="提前终止周期" prop="purchaseCount">
                <v-input-number
                  v-model="form.purchaseCount"
                  :width="80"
                  :max="max"
                  controls
                  controlsPosition="right"
                  @change="countChange(2)"
                  @blur="countBlur"
                />
                <span class="right-tip" style="margin: 0 20px 0 10px">{{
                  dayType
                }}</span>
              </el-form-item>
            </template>
          </template>

          <el-form-item
            v-if="isAdd||(!isAdd&&form.rentType == 3)"
            label="有效周期"
            :rules="[
              { required: true, message: '请选择有效周期', trigger: 'change' },
            ]"
            prop="startTime"
          >
          <el-form-item label="" v-if="form.rentType == 3">
            <v-checkbox class="order-checkbox"
                        label="长期有效"
                        @change="onLongEffectiveChange" 
                        v-model="longEffective" />
          </el-form-item>
            <v-datepicker2
              v-if="form.rentType == 3&&longEffective"
              :startTime.sync="form.startTime"
              :endTime.sync="form.endTime"
              type="rangedatetimer"
              :end-disabled="true"
            ></v-datepicker2>
            <v-datepicker
              v-else
              :startTime.sync="form.startTime"
              :endTime.sync="form.endTime"
              type="rangedatetimer"
              @schange="dateChange"
            ></v-datepicker>
          </el-form-item>
          <el-form-item v-else-if="form.rentType !== 3" label="有效周期">
            <span>{{ form.startTime }} —— {{ form.endTime }}</span>
          </el-form-item>
          <el-form-item v-if="!isAdd" label="剩余有效天数">
            <span>{{ leftDay }}天</span>
          </el-form-item>
          <el-form-item
            v-if="!isAdd"
            label="车卡状态"
            prop="status"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change',
              },
            ]"
          >
            <v-select
              v-model="form.status"
              :options="carCardStatusOps"
              :width="width"
              :disabled="!isAdd"
            ></v-select>
          </el-form-item>
            <el-form-item label="备注">
                <v-textarea v-model="form.remark" placeholder="备注"></v-textarea>
            </el-form-item>
        </col2-block>
        <col2-block title="支付信息" v-if="payFlag && form.rentType !== 3">
          <div class="money-bottom">
            <col2-item
              :span="24"
              label="订单金额"
              :text="(form.type == 2 ? '-' : '') + '￥' + form.orderMoneyTotal"
            />
          </div>
          <el-form-item label="优惠金额" prop="orderMoneyDiscount">
            <v-input-number
              v-model="form.orderMoneyDiscount"
              :width="width"
              :min="0"
              :max="parseInt(form.orderMoneyTotal)"
              @blur="orderMoneyDiscountBlur"
            />
            元
          </el-form-item>
          <div class="money-bottom">
            <col2-item
              :span="24"
              label="实付金额"
              :text="(form.type == 2 ? '-' : '') + '￥' + orderMoney"
            />
          </div>
          <el-form-item
            label="支付方式"
            prop="payment"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change',
              },
            ]"
          >
            <v-select
              v-model="form.payment"
              :options="paymentOpts"
              :width="width"
            ></v-select>
          </el-form-item>
        
        </col2-block>
      </col2-detail>
    
      <template #saveAfterSlot>
        <v-button
          type="danger"
          text="删除车卡"
          @click="delCard()"
          v-show="delFlag"
        ></v-button>
      </template>
    </form-panel>
    <remark-log-dialog :visible.sync="remarkLogVisible"></remark-log-dialog>
    <el-dialog
      title="添加新用户"
      :visible.sync="userDialog"
      width="440px"
      class="dialog"
      @close="closeDialog"
    >
      <el-form
        ref="userForm"
        :model="userForm"
        :rule="rule"
        label-width="140px"
        label-suffix="："
        size="medium"
      >
        <el-form-item
          label="姓名"
          prop="ownerName"
          :rules="[
            { required: true, message: '请填写姓名', trigger: 'change' },
          ]"
        >
          <v-input v-model="userForm.ownerName"></v-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="ownerPhone"
          :rules="[
            { required: true, message: '请填写手机号', trigger: 'change' },
          ]"
        >
          <v-input v-model="userForm.ownerPhone"></v-input>
        </el-form-item>
        <el-form-item
          label="证件类型"
          prop="cardType"
          :rules="[
            { required: true, message: '请选择证件类型', trigger: 'change' },
          ]"
        >
          <v-select
            v-model="userForm.cardType"
            :options="cardTypeOpts"
            :width="width"
          ></v-select>
        </el-form-item>
        <el-form-item
          label="证件号"
          prop="cardNum"
          :rules="[
            { required: true, message: '请输入证件号', trigger: 'blur' },
          ]"
        >
          <v-input
            v-model="userForm.cardNum"
            placeholder="请输入证件号"
            :width="width"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="所属项目"
          prop="communityId"
          :rules="{
            required: true,
            message: '请选择所属项目',
            trigger: 'change',
          }"
        >
          <v-select2
            placeholder="查询项目"
            v-model="userForm.communityId"
            v-bind="projectParams"
            :width="width"
            ref="userCommunity"
          ></v-select2>
        </el-form-item>
        <el-form-item
          label="所在房号"
          prop="roomId"
          :rules="{
            required: true,
            message: '请选择所在房号',
            trigger: 'change',
          }"
        >
          <v-select2
            v-model="userForm.roomId"
            v-bind="userRoomParams"
            :subjoin="userRoomSubjoin"
            placeholder="选择房号"
            :width="width"
            :disabled="
              !userForm.communityId || userForm.communityId.length === 0
            "
          ></v-select2>
        </el-form-item>
        <el-form-item
          label="身份"
          prop="userType"
          :rules="[
            { required: true, message: '请选择身份', trigger: 'change' },
          ]"
        >
          <v-select
            v-model="userForm.userType"
            :options="userTypeOps"
          ></v-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitOpera">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加车辆"
      :visible.sync="addCarDialog"
      width="700px"
      class="carDialog"
      @close="closeCarDialog"
    >
      <AddCarDialog 
        @submitSuccess="handleAddCarSuccess" 
        :userId="form.userId" 
        :houseId="form.userHouseId"
        :communityId="form.communityId"
        :edit="isEdit"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  getCardDetailURL,
  saveCardURL,
  getOwnerListURL,
  getCarportListURL,
  getParkingByCommunityURL,
  getSuitByLocationURL,
  getLocationBySuitURL,
  getUserRoomsListURL,
  batchURL,
  getCarListByUser
} from "./api";
import {
  carCardStatusOps,
  cardTypeOpts,
  cardTypeMap,
  paymentOpts,
  rentTypeOps,
  rentTypeMap,
  setUserTypeOps,
} from "./map";
import { Col2Block, Col2Item, Col2Detail, CheckboxPlus } from "components/bussiness";
import { vCheckbox } from 'components/control'
import { carPlateRegular } from "common/regular";
import { normalCommunityParams } from "common/select2Params";
import moment from "moment";
import RemarkLogDialog from "./RemarkLogDialog";
import { generateMapByOpts } from "common/utils";
import AddCarDialog from './AddCarDialog'

export default {
  name: "",
  components: {
    Col2Block,
    Col2Item,
    Col2Detail,
    RemarkLogDialog,
    CheckboxPlus,
    vCheckbox,
    AddCarDialog
  },
  data() {
    // 车牌正则
    // const regularCheckout = (rule, value, callback) => {
    //   if (!value || value.length === 0 || !carPlateRegular.test(value)) {
    //     callback(new Error("请输入正确的车牌号"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      addCarDialog: false,
      isPlateNumberChecked: true,
      orientTypeOps: [],
      max: 13,
      rule: {},
      userTypeOps: setUserTypeOps(2),
      userDialog: false,
      rentTypeMap,
      isWatch: true,
      isRecord: false,
      cardTypeOpts: cardTypeOpts,
      rentTypeOps: rentTypeOps.slice(1, 4),
      cardTypeMap,
      width: 200,
      submitConfig: {},
      paymentOpts: paymentOpts,
      carCardStatusOps: carCardStatusOps.slice(2),
      userParams: {
        searchUrl: getOwnerListURL,
        request: {
          text: "ownerName",
        },
        response: {
          value: "mobileNum",
          text: "name",
        },
      },
      projectParams: normalCommunityParams,
      carportParams: {
        searchUrl: getCarportListURL,
        request: {
          text: "name",
        },
        response: {
          text: "roomNumName",
          value: "id",
        },
      },
      form: {
        radioTypeLabel: "续期",
        type: 1,
        userId: "",
        mobileNum: "",
        userName: "",
        userCardType: undefined,
        userCardNum: "",
        communityId: "",
        plateNumberList: [],
        carLength: [{ cardNum: "" }],
        rentType: 1, // 车位类型
        houseId: "",
        suitId: "", // 套餐id
        startTime: "",
        endTime: "",
        status: 1,
        parkingId: undefined,
        payment: "支付宝",
        purchaseCount: 0,
        orderMoneyTotal: "0",
        orderMoney: "0",
        roomNumId: undefined, // 车位位置,数字类型
        roomNumName: "",
        orderMoneyDiscount: "0",
        remark:""
      },
      detailItem: {},
      // plateNumberRules: [
      //   { required: true, validator: regularCheckout, trigger: "blur" },
      // ],
      isAdd: false,
      // 是否是回显
      // isEcho: false,
      remark: "",
      remarkLogVisible: false,
      active: 0,
      // parkTypeOps: [],
      parkTypeMap: {},
      // SuitTypeOps: [],
      // 删除车卡出现
      delFlag: false,
      // 是否可添加车卡标记位
      addFlag: false,
      dayType: "",
      // 设置时是否显示支付信息标记位
      payFlag: true,
      // 新增时添加车卡后不可修改个人信息
      canEditFlag: true,
      leftDay: "0",
      curEndTime: "",
      parkParams: {
        searchUrl: getLocationBySuitURL,
        request: {
          text: "locationName",
          value: "locationId",
        },
        response: {
          text: "parkingName",
          value: "parkingId",
        },
      },
      parkParams1: {
        searchUrl: getLocationBySuitURL,
        request: {
          text: "locationName",
          value: "locationId",
        },
        response: {
          text: "roomNumName",
          value: "roomNumId",
        },
      },
      suitParams: {
        searchUrl: getSuitByLocationURL,
        request: {
          text: "suitName",
          value: "suitId",
        },
        response: {
          text: "suitName",
          value: "id",
        },
      },
      userForm: {
        ownerName: "",
        ownerPhone: "",
        cardNum: "",
        cardType: undefined,
        userType: undefined,
        roomId: "",
        communityId: "",
      },
      userRoomParams: {
        searchUrl: getUserRoomsListURL,
        method: "POST",
        request: {
          text: "roomNum",
          value: "houseId",
        },
        response: {
          text: "name",
        },
      },
      isEdit: false, // 控制新增车辆的输入用户名显示隐藏
      longEffective: false //长期有效是否被选
    };
  },

  created() {
    // 默认新增
    let add = true;
    if (this.$route.query && this.$route.query.id) {
      // 编辑put
      add = false;
      this.isEdit = true;
      this.addFlag = true;
      this.payFlag = false;
    }
    this.isAdd = add;
    this.submitConfig = {
      submitMethod: add ? "post" : "put",
      queryUrl: getCardDetailURL,
      submitUrl: saveCardURL,
    };

    let title = add ? "新增" : "编辑";
    // 设置title
    this.$setBreadcrumb(title);
  },

  // 加载详情数据
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.active = this.$route.query.id;
      // 获取详情
      const { id } = this.$route.query;
      this.$refs.formPanel.getData({ id });
    }
  },

  methods: {
    // inputChange(index) {
    //   let tempArr = [];
    //   for (var i = 0; i <= this.form.plateNumberList.length - 1; i++) {
    //     if(i!=index) {
    //       tempArr.push(this.form.plateNumberList[i].plateNumber);
    //     }
    //   }
      
    //   let valueData = this.form.plateNumberList[index].plateNumber;
    //   console.log(tempArr,222,valueData,tempArr.indexOf(valueData))
    //   if (
    //     tempArr.indexOf(valueData) != -1 
    //   ) {
    //     this.$message({
    //       type: "error",
    //       message: "不能添加重复车牌号",
    //       center: true,
    //     });
    //     this.form.plateNumberList[index].plateNumber = "";
    //   }
    // },
    radioTypeChange() {
      if (this.form.radioTypeLabel == "续期") {
        this.form.type = 1;
        if (this.form.suitType === 1) {
          this.dayType = "月";
        } else if (this.form.suitType === 2) {
          this.dayType = "年";
        } else if (this.form.suitType === 4) {
          this.dayType = "日";
        } else {
          this.dayType = "";
        }
        this.form.payment = "支付宝";
        this.paymentOpts = paymentOpts;
        this.form.purchaseCount = 0;
      } else if (this.form.radioTypeLabel == "终止") {
        this.dayType = "月";
        this.form.type = 2;
        this.form.payment = "现金";
        this.form.purchaseCount = 0;
        this.paymentOpts = paymentOpts.slice(2, 3);
      }
    },
    plusMinusAction(add, index) {
      if (!this.form.mobileNum) {
        this.$message({
          type: "error",
          message: "请先填写手机号",
          center: true,
        });
        return
      }
      if (this.form.mobileNum) {
        this.isEdit = true;
      }
      if (add) {
        // 添加
        // if (this.form.plateNumberList.length < 5) {
          // this.form.plateNumberList.push({
          //   plateNumber: "",
          // });
          this.addCarDialog = true;
        // } else {
        //   this.$message({
        //     type: "error",
        //     message: "最多只能添加5个车牌号",
        //     center: true,
        //   });
        // }
      } else {
        // 减少
        this.form.plateNumberList.splice(index, 1);
      }
    },
    // 用户
    userChange(data) {
      let userCommunityId = data ? data.communityId : "";
      let userHouseId = data ? data.houseId : "";
      let userName = data ? data.userName : "";
      let userCardType = data ? data.cardType : "";
      let userCardNum = data ? data.cardNum : "";
      let userId = data ? data.id : "";
      this.form.communityId = userCommunityId;
      this.form.houseId = userHouseId;
      this.form.userHouseId = userHouseId;
      this.form.userName = userName;
      this.form.userCardType = userCardType == 0 ? undefined : userCardType;
      this.form.userCardNum = userCardNum;
      this.form.userId = userId;
      this.handleGetCarList(data.id);
    },
    // 用户车辆信息
    handleGetCarList(id) {
      this.$axios({
        url: getCarListByUser,
        // method: 'POST',
        // data: {
        //   id: id
        // }
        method: 'GET',
        params: {
          communityId: this.form.communityId,
          userId: this.form.userId 
        }
      }).then(res => {
        this.form.plateNumberList = []
        res.data.forEach(item => {
          this.form.plateNumberList.push({ plateNumber: item.plateNumber, isChecked: true })
        })
      })
    },
    // 套餐改变
    suitChange(data) {
      this.form.suitPrice = data ? data.price : "";
      if (this.form.purchaseCount && this.form.suitPrice) {
        let v = this.form.purchaseCount.toString() * this.form.suitPrice;
        this.form.orderMoneyTotal = v.toString();
        // this.form.orderMoney = v.toString();
      }
      if (data) {
        if (data.suitType === 1) {
          this.dayType = "月";
        } else if (data.suitType === 2) {
          this.dayType = "年";
        } else if (data.suitType === 4) {
          this.dayType = "日";
        } else {
          this.dayType = "";
        }
      }
      if (this.form.startTime&&!this.longEffective) {
        this.dateChange(this.form.startTime);
      }
    },
    // 项目
    projectChange(data) {
      let communityName = data ? data.name : "";
      this.form.communityName = communityName;
      this.form.suitId = undefined;
      this.form.parkingId = undefined;
      this.form.roomNumId = undefined;
      this.form.roomNumName = "";
      this.$refs.suit && this.$refs.suit.clearValue();
      this.$refs.park && this.$refs.park.clearValue();
      this.$refs.room && this.$refs.room.clearValue();
      this.dayType = "";
    },
    // 租赁时间改变
    countChange(data, val) {
      if (this.form.type === 1 && !this.isAdd) {
        this.form.purchaseCount = data
        if (data === 2) { data = 1 } // 避免与终止的 data 参数混淆
        // 解决租赁时间修改导致有效周期异常及价格 NaN 的问题
      } else if (this.form.type === 1 && this.isAdd) {
        this.form.purchaseCount = data.target.value
      }
      this.payFlag = true;
      this.addFlag = false;
      if (this.form.purchaseCount && this.form.suitPrice) {
        let v = this.form.purchaseCount.toString() * this.form.suitPrice;
        this.form.orderMoneyTotal = v.toString();
      } else {
        this.form.orderMoneyTotal = "0";
      }
      if (this.form.startTime) {
        this.dateChange(this.form.startTime, data);
      }
      if ((data === "0" || data === "") && !this.isAdd) {
        this.payFlag = false;
        this.addFlag = true;
      }
    },
    async update(data) {
      let form = Object.assign(this.form, data);
      // 获取所有车牌
      const res = await this.$axios({
        url: getCarListByUser,
        method: 'GET',
        params: {
          communityId: this.form.communityId,
          userId: this.form.userId 
        }
      })
      let plateNumberList = [];
      res.data.forEach(item => {
        plateNumberList.push({ plateNumber: item.plateNumber, isChecked: false })
      })
      if (form.carNums) {
        // 默认勾上已保存过的车牌
        plateNumberList.forEach((car, index) => {
          form.carNums.forEach((item) => {
            // plateNumberList.push({ plateNumber: item, isChecked: true });
            if (car.plateNumber === item) {
              plateNumberList[index].isChecked = true
            }
          });
        })
      }
      if (plateNumberList.length === 0) {
        plateNumberList.push({ plateNumber: "" });
      }
      form.plateNumberList = plateNumberList;
      let carLength = [];
      if (form.cardIds) {
        form.cardIds.forEach((item) => {
          carLength.push({ cardNum: item });
        });
      }
      if (carLength.length === 0) {
        carLength.push({ cardNum: "" });
      }
      form.carLength = carLength;
      form.payment = "支付宝";
      form.purchaseCount = 0;
      if (form.suitType === 1) {
        this.dayType = "月";
      } else if (data.suitType === 2) {
        this.dayType = "年";
      } else if (data.suitType === 4) {
        this.dayType = "日";
      } else {
        this.dayType = "";
      }
      this.form = form;
      this.curEndTime = this.form.endTime;
      if(this.form.endTime.indexOf('2099-12-31')>-1){
        this.longEffective = true
      }
      // 剩余天数计算
      let isLeaveStart = Math.floor(
        (new Date(this.form.startTime).getTime() - new Date().getTime()) /
          86400000
      );
      let leaveDay = Math.floor(
        (new Date(this.form.endTime).getTime() - new Date().getTime()) /
          86400000
      );
      this.leftDay =
        isLeaveStart > 0
          ? Math.floor(
              (new Date(this.form.endTime).getTime() -
                new Date(this.form.startTime).getTime()) /
                86400000
            )
          : leaveDay > 0
          ? leaveDay
          : 0;
      let maxMonths = moment(this.form.endTime).diff(
        moment(new Date()),
        "months"
      );
      console.log(maxMonths,"-______")
      if (maxMonths < 1) {
        this.max = 0;
      } else {
        this.max = maxMonths;
      }
    },
    submitBefore(data) {
      let tempList = [];
      let plateNumberList = data.plateNumberList;
      plateNumberList.forEach((item) => {
        if (item.isChecked) {
          tempList.push(item.plateNumber);
        }
      });
      data.orderMoneyDiscount = data.orderMoneyDiscount.toString();
      data.orderMoney = this.orderMoney;
      data.carNums = tempList;
      // 删除多余字段
      delete data.plateNumberList;
      if (data.rentType == 2) {
        data.roomNumName = "";
      }
      if (data.carNums.length === 0) {
        this.$message.error('请选择车牌号')
        return false
      }
      return true;
    },
    submitSuccess(d) {
      this.submitConfig = {
        submitMethod: "put",
        queryUrl: getCardDetailURL,
        submitUrl: saveCardURL,
      };
      this.$alert("保存成功！");
      this.addFlag = true;
      this.isAdd = false;
      this.payFlag = false;
      this.delFlag = false;
      let a = {};
      a.id = d.id;
      this.active = d.id;
      this.$refs.formPanel.getData(a);
    },
    // 切换车卡
    changeCard(num) {
      if (!this.addFlag) {
        this.$alert("请先保存上一车卡信息后再继续！");
        return;
      }
      this.form.radioTypeLabel = "续期"
      this.form.type=1
      this.active = num;
      let a = {};
      a.id = this.active;
      this.$refs.formPanel.getData(a);
    },
    // 添加车卡
    addCard() {
      if (!this.addFlag) {
        this.$alert("请先保存上一车卡信息后再继续！");
        return;
      }
      this.canEditFlag = false;
      this.form.carLength.push({ cardNum: "0" });
      this.active = 0;
      this.delFlag = true;
      this.addFlag = false;
      this.isAdd = true;
      this.payFlag = true;
      let am = {
        communityId: this.form.communityId,
        plateNumberList: [{ plateNumber: "" }],
        rentType: 1,
        houseId: "",
        suitId: undefined,
        startTime: "",
        endTime: "",
        status: 1,
        parkingId: undefined,
        payment: "支付宝",
        purchaseCount: 0,
        orderMoneyTotal: "0",
        orderMoney: "0",
        communityName: this.form.communityName,
        roomNumId: "",
        orderMoneyDiscount: "0",
      };
      this.form = Object.assign({}, this.form, am);
      this.submitConfig = {
        submitMethod: "post",
        queryUrl: getCardDetailURL,
        submitUrl: saveCardURL,
      };
      this.handleGetCarList()
    },
    // 删除车卡
    delCard() {
      this.form.carLength.splice(-1, 1);
      this.delFlag = false;
      this.addFlag = true;
      this.payFlag = false;
      this.isAdd = false;
      this.active = this.form.carLength[this.form.carLength.length - 1].cardNum;
      let a = {};
      a.id = this.active;
      this.$refs.formPanel.getData(a);
      this.submitConfig = {
        submitMethod: "put",
        queryUrl: getCardDetailURL,
        submitUrl: saveCardURL,
      };
    },
    // 输入开始日期，结束日期自动补全
    dateChange(data, feeType) {
      if (data === "") {
        this.form.endTime = "";
        return;
      }
      if (this.isAdd) {
        if (this.dayType === "月") {
          this.form.endTime = moment(data)
            .add(this.form.purchaseCount, "months")
            .format("YYYY-MM-DD HH:mm:ss");
        } else if (this.dayType === "年") {
          this.form.endTime = moment(data)
            .add(this.form.purchaseCount, "years")
            .format("YYYY-MM-DD HH:mm:ss");
        } else if (this.dayType === "日") {
          this.form.endTime = moment(data)
            .add(this.form.purchaseCount, "days")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      } else {
        if (this.dayType === "月") {
          if (feeType == 2) {
            this.form.endTime = moment(this.curEndTime)
              .subtract(this.form.purchaseCount, "months")
              .format("YYYY-MM-DD HH:mm:ss");
          } else {
            this.form.endTime = moment(this.curEndTime)
              .add(this.form.purchaseCount, "months")
              .format("YYYY-MM-DD HH:mm:ss");
          }
        } else if (this.dayType === "年") {
          this.form.endTime = moment(this.curEndTime)
            .add(this.form.purchaseCount, "years")
            .format("YYYY-MM-DD HH:mm:ss");
        } else if (this.dayType === "日") {
          this.form.endTime = moment(this.curEndTime)
            .add(this.form.purchaseCount, "days")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }
    },
    // 车位类型改变
    truckTypeChange(data) {
      this.form.suitId = undefined;
      this.form.parkingId = undefined;
      this.form.roomNumId = undefined;
      this.form.roomNumName = "";
      this.longEffective = false
      this.$refs.suit && this.$refs.suit.clearValue();
      this.$refs.park && this.$refs.park.clearValue();
      this.$refs.room && this.$refs.room.clearValue();
    },
    // 车位改变时
    roomChange(data) {
      if (data) {
        this.form.roomNumName = data.roomNumName;
      }
    },
    submitOpera() {
      this.$refs.userForm.validate((result, object) => {
        if (result) {
          let data = {
            ...this.userForm,
          };
          this.$axios.post(batchURL, data).then((res) => {
            if (res.status == 100) {
              this.userDialog = false;
              this.$message({
                type: "success",
                message: "操作成功",
                center: true,
              });
            }
          });
        }
      });
    },
    closeDialog() {
      this.$refs["userForm"].resetFields();
      this.$refs["userForm"].clearValidate();
      this.userDialog = false;
    },
    addUser() {
      this.userDialog = true;
    },
    countBlur() {
      if (this.form.purchaseCount) {
        this.form.purchaseCount = parseInt(this.form.purchaseCount);
        if (this.form.purchaseCount < 1) {
          this.form.purchaseCount = 1;
        }
      }
    },
    orderMoneyDiscountBlur() {
      if (!this.form.orderMoneyDiscount) {
        this.form.orderMoneyDiscount = "0";
      }
    },
    closeCarDialog () {
      this.addCarDialog = false
    },
    handleAddCarSuccess(data) {
      if (data.status) {
        this.$message({
          message: '新增车辆成功',
          type: 'success'
        })
        this.addCarDialog = false
        // 将刚刚新增成功的车牌添加到车牌列表中，默认选中
        const plateNumberList = this.form.plateNumberList
        plateNumberList.push({ plateNumber: data.carNum, isChecked: true })
        this.form.plateNumberList = plateNumberList
      }
    },
    onLongEffectiveChange(data){
      if(data){
        this.form.endTime = '2099-12-31 23:59'
      }
    }
  },
  computed: {
    // maxMoney () {
    //   return parseInt(this.form.orderMoneyTotal)
    // },
    orderMoney() {
      return (
        this.form.orderMoneyTotal - this.form.orderMoneyDiscount
      ).toString();
    },
    minDate() {
      return this.form.startTime;
    },
    maxDate() {
      return this.form.endTime;
    },
    parkSubjoin() {
      return {
        communityId: this.form.communityId,
        rentType: this.form.rentType,
        userId: this.form.userId,
        suitId: this.form.suitId,
      };
    },
    suitSubjoin() {
      let locationId = "";
      if (this.form.rentType == 2) {
        locationId = this.form.parkingId;
      } else {
        locationId = this.form.roomNumId;
      }
      return {
        communityId: this.form.communityId,
        rentType: this.form.rentType,
        locationId: locationId,
      };
    },
    userRoomSubjoin() {
      return { communityId: this.userForm.communityId };
    }
  },
  watch: {
    "form.orderMoneyTotal": {
      handler(newVal, oldVal) {
        if (newVal < this.form.orderMoneyDiscount) {
          this.form.orderMoneyDiscount = newVal;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.parkCardManageForm-wrapper {
  .second-category {
    margin-right: 30px;
  }
  .car-number {
    margin-bottom: 20px;
  }

  .content-wrapper {
    display: flex;
    // margin-bottom: 20px;

    .first-margin {
      margin-right: 30px;
    }
  }
  .link {
    color: #48a8ff;
    cursor: pointer;
  }
  .add-card {
    padding-bottom: 20px;
    > div {
      display: inline-block;
      line-height: 1;
      background: #fff;
      border: 1px solid #dcdfe6;
      font-weight: 500;
      color: #606266;
      -webkit-appearance: none;
      text-align: center;
      cursor: pointer;
      padding: 12px 0;
      font-size: 14px;
      width: 120px;
      &.active {
        color: #fff;
        background-color: #409eff;
        border-color: #409eff;
      }
    }
  }

  .dialog {
    text-align: left;
  }

  .money-bottom {
    padding-bottom: 50px;
  }
  .checkbox-plus-wrapper {
    flex-direction: column;
  }
  .carDialog {
    ::v-deep .header .el-button {
      display: none;
    }
  }
}
</style>
