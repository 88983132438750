var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.visibleInner, title: "备注日志" },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "500px" } },
        [
          _c("list", {
            ref: "list",
            attrs: {
              searchUrl: _vm.searchUrl,
              searchParams: _vm.searchParams,
              headers: _vm.headers,
              hasOperateColumn: false,
              showOverflowTooltip: false,
            },
            on: {
              "update:searchParams": function ($event) {
                _vm.searchParams = $event
              },
              "update:search-params": function ($event) {
                _vm.searchParams = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }