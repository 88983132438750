var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "storeRoomForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c("div", { staticClass: "cell-title" }, [
            _c("span", { staticClass: "pre-point" }),
            _vm._v("车辆基本信息\n      "),
          ]),
          !_vm.edit
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户姓名",
                    rules: [
                      {
                        required: true,
                        message: "请选择用户",
                        trigger: "change",
                      },
                    ],
                    prop: "houseId",
                  },
                },
                [
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: { width: 400, echoRequestData: _vm.echohouseId },
                        on: { onChange: _vm.houseIdChange },
                        model: {
                          value: _vm.form.houseId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "houseId", $$v)
                          },
                          expression: "form.houseId",
                        },
                      },
                      "v-select2",
                      _vm.houseParams,
                      false
                    )
                  ),
                  _c("div", [
                    _vm._v("\n          注:如查找不到姓名，请到\n          "),
                    _c(
                      "span",
                      {
                        staticClass: "blueColor",
                        on: { click: _vm.goPageUser },
                      },
                      [_vm._v("用户资料管理")]
                    ),
                    _vm._v(" 模块去新增用户数据\n        "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "车牌号",
                rules: [
                  { required: true, message: "请填写车牌号", trigger: "blur" },
                ],
                prop: "carNum",
              },
            },
            [
              _c("v-input", {
                model: {
                  value: _vm.form.carNum,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "carNum",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.carNum",
                },
              }),
              _c("span", [_vm._v("格式: 浙A123ab， 请使用半角数字和字母")]),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "车辆品牌" } }, [
            _c(
              "div",
              { staticClass: "multi-form" },
              [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        echoRequestData: _vm.echoCarBrand,
                        response: { text: "value", value: "value" },
                      },
                      on: { onChange: _vm.carBrandChange },
                      model: {
                        value: _vm.form.carBrand,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "carBrand", $$v)
                        },
                        expression: "form.carBrand",
                      },
                    },
                    "v-select2",
                    _vm.carBrandParams,
                    false
                  )
                ),
                _c("v-select", {
                  staticClass: "mar-left",
                  attrs: { options: _vm.carModelOps },
                  model: {
                    value: _vm.form.carModel,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "carModel", $$v)
                    },
                    expression: "form.carModel",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "颜色" } },
            [
              _c("v-select", {
                attrs: { options: _vm.carColorlOps },
                model: {
                  value: _vm.form.carColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "carColor", $$v)
                  },
                  expression: "form.carColor",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行驶证" } },
            [
              _c("v-uploader", {
                attrs: {
                  action: _vm.uploadURL,
                  limit: 2,
                  imgUrls: _vm.drivingLicensePicList,
                },
                on: {
                  "update:imgUrls": function ($event) {
                    _vm.drivingLicensePicList = $event
                  },
                  "update:img-urls": function ($event) {
                    _vm.drivingLicensePicList = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "tip",
                    fn: function () {
                      return undefined
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }