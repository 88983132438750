<template>
  <el-dialog :visible="visibleInner" title="备注日志" @close="onClose">
    <div style="height: 500px">
      <list
        ref="list"
        :searchUrl="searchUrl"
        :searchParams.sync="searchParams"
        :headers="headers"
        :hasOperateColumn="false"
        :showOverflowTooltip="false"
      >
      </list>
    </div>
  </el-dialog>
</template>
<script>
import { getLogListURL } from './api'
export default {
  name: 'RemarkLogDialog',
  props: {
    visible: Boolean
  },
  data () {
    return {
      visibleInner: false,
      searchUrl: getLogListURL,
      searchParams: {
        carParkCardId: this.$route.query.id
      },
      headers: [
        {
          prop: 'intime', // TODO
          label: '备注时间'
        },
        {
          prop: 'inuserName', // TODO
          label: '备注人'
        },
        {
          prop: 'remark', // TODO
          label: '备注内容'
        }
      ]
    }
  },
  methods: {
    onClose () {
      this.visibleInner = false
      this.$emit('update:visible', this.visibleInner)
    }
  },
  watch: {
    visible (newVal, oldVal) {
      this.visibleInner = newVal
      if (newVal) {
        this.$nextTick(() => {
          this.searchParams.carParkCardId = this.$route.query.id
        })
      }
    }
  }
}
</script>
